import { makeStyles } from '@mui/styles';
export var styles = {
  filterBtnStyles: {
    paddingLeft: '2.5em',
    paddingRight: '2.5em'
  }
};
export var classStyles = makeStyles(function (theme) {
  return {
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '12px',
      paddingRight: '12px',
      minWidth: '35px',
      height: '2.499em',
      alignContent: 'left',
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
      borderRadius: '4px'
    },
    input: {
      minHeight: '55px',
      flex: 1,
      fontSize: '14px',
      color: theme.palette.primary.dark,
      '& input': {
        marginTop: '2px'
      },
      width: '17em'
    },
    form: {
      width: '100%',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    inputLabelShrink: {
      fontSize: '14px'
    },
    root: {
      width: '100%',
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '2px solid #1160F7',
      boxShadow: 'none',
      height: '2.5em',
      borderRadius: '0px'
    },
    formControl: {
      flex: 1,
      width: '100%'
    }
  };
});