import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _transferRates, _spotRateMapping, _INDIA, _PHP, _USA, _SRI_LANKA, _EUR, _feeRates, _trueCostList;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var tableCellStyle = {
  borderRight: '1px solid #CACCD5'
};
export var REMITLY = 'remitly';
export var TRANSFER_WISE = 'transfer_wise';
export var WORLD_REMIT = 'world_remit';
export var XOOM = 'xooom';
export var CIBC = 'cibc';
export var REMITBEE = 'remitbee';
export var SRI_LANKA = 210;
export var INDIA = 103;
export var USA = 236;
export var EUR = 235;
export var PHP = 175;
export var CAN = 40;
export var SCOTIABANK = 'scotiabank';
export var TD_BANK = 'td_bank';
export var BMO = 'bmo';
export var RBC = 'rbc';
export var NATIONAL_BANK = 'national_bank';
export var activeCountries = [SRI_LANKA, INDIA, USA, EUR, PHP];
export var tableRows = [[{
  label: 'Instant Transfer Time',
  type: 'string'
}, {
  label: 'Same day',
  type: 'string'
}, {
  label: 'Same day',
  type: 'string'
}, {
  label: 'Same day',
  type: 'string'
}, {
  label: 'Same day',
  type: 'string'
}, {
  label: 'Same day',
  type: 'string'
}, {
  label: 'blank',
  type: 'icon'
}], [{
  label: 'Economic Transfer time',
  type: 'string'
}, {
  label: '1 Day',
  type: 'string'
}, {
  label: '3-5 Days',
  type: 'string'
}, {
  label: '3 business Days',
  type: 'string'
}, {
  label: '3 business Days',
  type: 'string'
}, {
  label: '3 business Days',
  type: 'string'
}, {
  label: '2 Days',
  type: 'string'
}], [{
  label: 'Pay with E-transfer',
  type: 'string'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}], [{
  label: 'Pay With Bill payment',
  type: 'string'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}], [{
  label: 'Pay with EFT',
  type: 'string'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}], [{
  label: 'Pay with Debit',
  type: 'string'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'check',
  type: 'icon'
}], [{
  label: 'Pay with interac online',
  type: 'string'
}, {
  label: 'check',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}, {
  label: 'blank',
  type: 'icon'
}]];
export var labelRows = ['Instant Option Transfer Time', 'Economic Transfer time', 'Pay with E-transfer', 'Pay With Bill payment', 'Pay with EFT', 'Pay with Debit', 'Pay with interac online'];
export var mobileRows = [{
  labelPath: '/remitbee_logo.png',
  provider: REMITBEE,
  content: []
}, {
  labelPath: '/icons/other/scotiabank.svg',
  provider: SCOTIABANK,
  content: []
}, {
  labelPath: '/icons/other/cibc_logo.svg',
  provider: CIBC,
  content: []
}, {
  labelPath: '/icons/other/td_bank.svg',
  provider: TD_BANK,
  content: []
}, {
  labelPath: '/icons/other/bmo.svg',
  provider: BMO,
  content: []
}, {
  labelPath: '/icons/other/rbc.svg',
  provider: RBC,
  content: []
}, {
  labelPath: '/icons/other/national_bank.svg',
  provider: NATIONAL_BANK,
  content: []
}];
export var transferMobileRows = [{
  labelPath: '/remitbee_logo.png',
  provider: REMITBEE,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '1 Day'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }]
}, {
  labelPath: '/icons/other/remitly_logo.png',
  provider: REMITLY,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '3-5 Days'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'blank'
  }]
}, {
  labelPath: '/icons/other/transfer_wise_logo.svg',
  provider: TRANSFER_WISE,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '3 business Days'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'blank'
  }]
}, {
  labelPath: '/icons/other/world_remit_logo.svg',
  provider: WORLD_REMIT,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '3 business Days'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'blank'
  }]
}, {
  labelPath: '/icons/other/xoom_logo.svg',
  provider: XOOM,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '3 business Days'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'blank'
  }]
}, {
  labelPath: '/icons/other/cibc_logo.svg',
  provider: CIBC,
  content: [{
    type: 'string',
    label: 'Same day'
  }, {
    type: 'string',
    label: '2 Days'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'blank'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'check'
  }, {
    type: 'icon',
    label: 'blank'
  }]
}];
export var transferRates = (_transferRates = {}, _defineProperty(_transferRates, PHP, [{
  value: 36.4300,
  provider: REMITLY
}, {
  value: 36.8040,
  provider: REMITBEE
}, {
  value: 36.9146,
  provider: TRANSFER_WISE
}, {
  value: 36.3375,
  provider: WORLD_REMIT
}, {
  value: 36.356,
  provider: XOOM
}, {
  value: 36.451,
  provider: CIBC
}]), _defineProperty(_transferRates, INDIA, [{
  value: 56.1050,
  provider: REMITLY
}, {
  value: 56.4625,
  provider: REMITBEE
}, {
  value: 56.4762,
  provider: TRANSFER_WISE
}, {
  value: 56.1136,
  provider: WORLD_REMIT
}, {
  value: 56.156,
  provider: XOOM
}, {
  value: 56.106,
  provider: CIBC
}]), _defineProperty(_transferRates, USA, [{
  value: null,
  provider: REMITLY
}, {
  value: 0.7606,
  provider: REMITBEE
}, {
  value: 0.7644,
  provider: TRANSFER_WISE
}, {
  value: 0.7562,
  provider: WORLD_REMIT
}, {
  value: 0.7562,
  provider: XOOM
}, {
  value: 0.7510,
  provider: CIBC
}]), _defineProperty(_transferRates, SRI_LANKA, [{
  value: 135.3600,
  provider: REMITLY
}, {
  value: 145.7500,
  provider: REMITBEE
}, {
  value: 141.5934,
  provider: TRANSFER_WISE
}, {
  value: 140.9366,
  provider: WORLD_REMIT
}, {
  value: 140.5934,
  provider: XOOM
}, {
  value: 140.334,
  provider: CIBC
}]), _defineProperty(_transferRates, EUR, [{
  value: null,
  provider: REMITLY
}, {
  value: 0.6379,
  provider: REMITBEE
}, {
  value: 0.6424,
  provider: TRANSFER_WISE
}, {
  value: 0.6321,
  provider: WORLD_REMIT
}, {
  value: 0.6324,
  provider: XOOM
}, {
  value: 0.6340,
  provider: CIBC
}]), _transferRates);
export var spotRateMapping = (_spotRateMapping = {}, _defineProperty(_spotRateMapping, PHP, 36.98), _defineProperty(_spotRateMapping, CAN, 1.28242), _defineProperty(_spotRateMapping, INDIA, 56.75), _defineProperty(_spotRateMapping, USA, 1.28242), _defineProperty(_spotRateMapping, SRI_LANKA, 142.57), _defineProperty(_spotRateMapping, EUR, 0.64), _spotRateMapping);
export var feeRates = (_feeRates = {}, _defineProperty(_feeRates, INDIA, (_INDIA = {}, _defineProperty(_INDIA, REMITLY, [{
  rate: 100,
  fee: 1.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_INDIA, REMITBEE, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_INDIA, TRANSFER_WISE, [{
  rate: 100,
  fee: 2.93
}, {
  rate: 500,
  fee: 5.91
}, {
  rate: 1000,
  fee: 9.63
}, {
  rate: 3000,
  fee: 24.52
}, {
  rate: 9000,
  fee: 69.18
}]), _defineProperty(_INDIA, WORLD_REMIT, [{
  rate: 100,
  fee: 1.99
}, {
  rate: 500,
  fee: 4.99
}, {
  rate: 1000,
  fee: 6.99
}, {
  rate: 3000,
  fee: 16.99
}, {
  rate: 9000,
  fee: 24.99
}]), _defineProperty(_INDIA, XOOM, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_INDIA, CIBC, [{
  rate: 100,
  fee: 1.99
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _INDIA)), _defineProperty(_feeRates, PHP, (_PHP = {}, _defineProperty(_PHP, REMITLY, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_PHP, REMITBEE, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_PHP, TRANSFER_WISE, [{
  rate: 100,
  fee: 3.43
}, {
  rate: 500,
  fee: 6.57
}, {
  rate: 1000,
  fee: 10.49
}, {
  rate: 3000,
  fee: 26.16
}, {
  rate: 9000,
  fee: 73.19
}]), _defineProperty(_PHP, WORLD_REMIT, [{
  rate: 100,
  fee: 4.99
}, {
  rate: 500,
  fee: 4.99
}, {
  rate: 1000,
  fee: 4.99
}, {
  rate: 3000,
  fee: 4.99
}, {
  rate: 9000,
  fee: 4.99
}]), _defineProperty(_PHP, XOOM, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_PHP, CIBC, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _PHP)), _defineProperty(_feeRates, USA, (_USA = {}, _defineProperty(_USA, REMITLY, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_USA, REMITBEE, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_USA, TRANSFER_WISE, [{
  rate: 100,
  fee: 3.65
}, {
  rate: 500,
  fee: 6.23
}, {
  rate: 1000,
  fee: 9.46
}, {
  rate: 3000,
  fee: 22.37
}, {
  rate: 9000,
  fee: 61.12
}]), _defineProperty(_USA, WORLD_REMIT, [{
  rate: 100,
  fee: 3.99
}, {
  rate: 500,
  fee: 3.99
}, {
  rate: 1000,
  fee: 3.99
}, {
  rate: 3000,
  fee: 3.99
}, {
  rate: 9000,
  fee: 3.99
}]), _defineProperty(_USA, XOOM, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_USA, CIBC, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _USA)), _defineProperty(_feeRates, SRI_LANKA, (_SRI_LANKA = {}, _defineProperty(_SRI_LANKA, REMITLY, [{
  rate: 100,
  fee: 4.99
}, {
  rate: 500,
  fee: 4.99
}, {
  rate: 1000,
  fee: 4.99
}, {
  rate: 3000,
  fee: 4.99
}, {
  rate: 9000,
  fee: 4.99
}]), _defineProperty(_SRI_LANKA, REMITBEE, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_SRI_LANKA, TRANSFER_WISE, [{
  rate: 100,
  fee: 3.73
}, {
  rate: 500,
  fee: 6.94
}, {
  rate: 1000,
  fee: 10.96
}, {
  rate: 3000,
  fee: 27.03
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_SRI_LANKA, WORLD_REMIT, [{
  rate: 100,
  fee: 4.99
}, {
  rate: 500,
  fee: 5.99
}, {
  rate: 1000,
  fee: 6.99
}, {
  rate: 3000,
  fee: 9.99
}, {
  rate: 9000,
  fee: 9.99
}]), _defineProperty(_SRI_LANKA, XOOM, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_SRI_LANKA, CIBC, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _SRI_LANKA)), _defineProperty(_feeRates, EUR, (_EUR = {}, _defineProperty(_EUR, REMITLY, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_EUR, REMITBEE, [{
  rate: 100,
  fee: 2.99
}, {
  rate: 500,
  fee: 0
}, {
  rate: 1000,
  fee: 0
}, {
  rate: 3000,
  fee: 0
}, {
  rate: 9000,
  fee: 0
}]), _defineProperty(_EUR, TRANSFER_WISE, [{
  rate: 100,
  fee: 2.79
}, {
  rate: 500,
  fee: 5.38
}, {
  rate: 1000,
  fee: 8.60
}, {
  rate: 3000,
  fee: 21.52
}, {
  rate: 9000,
  fee: 60.27
}]), _defineProperty(_EUR, WORLD_REMIT, [{
  rate: 100,
  fee: 3.99
}, {
  rate: 500,
  fee: 3.99
}, {
  rate: 1000,
  fee: 3.99
}, {
  rate: 3000,
  fee: 3.99
}, {
  rate: 9000,
  fee: 3.99
}]), _defineProperty(_EUR, XOOM, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _defineProperty(_EUR, CIBC, [{
  rate: 100,
  fee: null
}, {
  rate: 500,
  fee: null
}, {
  rate: 1000,
  fee: null
}, {
  rate: 3000,
  fee: null
}, {
  rate: 9000,
  fee: null
}]), _EUR)), _feeRates);
export var exchangeRates = [{
  buyRate: 1.2761,
  sellRate: 1.2892,
  provider: REMITBEE
}, {
  buyRate: 1.247,
  sellRate: 1.3156,
  provider: SCOTIABANK
}, {
  buyRate: 1.2347,
  sellRate: 1.3275,
  provider: CIBC
}, {
  buyRate: 1.2456,
  sellRate: 1.3192,
  provider: TD_BANK
}, {
  buyRate: 1.2453,
  sellRate: 1.3147,
  provider: BMO
}, {
  buyRate: 1.2429,
  sellRate: 1.3116,
  provider: RBC
}, {
  buyRate: 1.2435,
  sellRate: 1.3115,
  provider: NATIONAL_BANK
}];
export var trueCostList = (_trueCostList = {}, _defineProperty(_trueCostList, REMITBEE, 6.32), _defineProperty(_trueCostList, SCOTIABANK, 35.42), _defineProperty(_trueCostList, CIBC, 47.72), _defineProperty(_trueCostList, TD_BANK, 36.82), _defineProperty(_trueCostList, BMO, 37.72), _defineProperty(_trueCostList, RBC, 39.52), _defineProperty(_trueCostList, NATIONAL_BANK, 38.92), _trueCostList);
export var getTableBodyStyle = function getTableBodyStyle(i, index) {
  var tableCell = _objectSpread({}, tableCellStyle);
  if (i !== tableRows.length - 1) {
    tableCell = _objectSpread(_objectSpread({}, tableCell), {}, {
      borderBottom: 'none'
    });
  }
  if (index === tableRows.length) {
    tableCell = _objectSpread(_objectSpread({}, tableCell), {}, {
      borderRight: 'none'
    });
  }
  if (index === 0) {
    tableCell = _objectSpread(_objectSpread({}, tableCell), {}, {
      borderRight: 'none'
    });
  }
  if (index === 1) {
    tableCell = _objectSpread(_objectSpread({}, tableCell), {}, {
      backgroundColor: '#F3F8FF',
      borderRight: 'none'
    });
  }
  return tableCell;
};
export var getTableBottomStyle = function getTableBottomStyle(index) {
  var bottomStyle = _objectSpread(_objectSpread({}, tableCellStyle), {}, {
    textAlign: 'center',
    color: '#626680',
    borderBottom: 'none'
  });
  if (index === mobileRows.length - 1 || index === 0) {
    bottomStyle = _objectSpread(_objectSpread({}, bottomStyle), {}, {
      borderRight: 'none'
    });
  }
  if (index === 0) {
    bottomStyle = _objectSpread(_objectSpread({}, bottomStyle), {}, {
      backgroundColor: '#F3F8FF'
    });
  }
  return bottomStyle;
};