import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { container } from '../../globalStyles';
var styles = function styles(theme) {
  var _title, _hero, _contentBottom;
  return {
    container: container,
    alignContainer: {
      alignItems: 'center'
    },
    title: (_title = {
      color: "white",
      margin: "1.75rem 0 0.875rem",
      textDecoration: "none",
      fontWeight: "300",
      fontSize: "3rem",
      opacity: "1",
      lineHeight: "1.2",
      paddingBottom: theme.spacing(3, 0)
    }, _defineProperty(_title, theme.breakpoints.down('sm'), {
      fontSize: "36px",
      textAlign: 'center'
    }), _defineProperty(_title, "@media (max-width: 320px)", {
      fontSize: "28px"
    }), _defineProperty(_title, "@media (max-width: 280px)", {
      fontSize: "35px"
    }), _defineProperty(_title, "marginTop", 'auto'), _defineProperty(_title, "marginBottom", 'auto'), _title),
    boldText: {
      fontWeight: "700"
    },
    hero: (_hero = {
      background: '#1160F7'
    }, _defineProperty(_hero, theme.breakpoints.down('md'), {
      justifyContent: 'center',
      padding: "140px 0 60px 0",
      background: '#1160F7'
    }), _defineProperty(_hero, theme.breakpoints.up('md'), {
      padding: "140px 0 80px 0",
      background: '#1160F7'
    }), _hero),
    bottomTitle: {
      fontWeight: 600,
      fontFamily: 'Inter'
    },
    content: {
      color: '#fff',
      fontWeight: '400',
      paddingTop: '24px',
      paddingBottom: '24px',
      fontFamily: 'Inter !important',
      fontSize: '20px'
    },
    contentBottom: (_contentBottom = {
      fontWeight: 400,
      color: '#626680',
      marginTop: theme.spacing(2)
    }, _defineProperty(_contentBottom, theme.breakpoints.up('xs'), {
      lineHeight: '44px',
      fontSize: '24px'
    }), _defineProperty(_contentBottom, theme.breakpoints.down('md'), {
      lineHeight: '36px',
      fontSize: '18px'
    }), _contentBottom),
    gridClass: _defineProperty({
      maxWidth: '150px'
    }, theme.breakpoints.up('md'), {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3)
    }),
    shortHero: _defineProperty({
      padding: "140px 0 220px 0 !important",
      height: '450px'
    }, theme.breakpoints.down('sm'), {
      marginBottom: '200px'
    }),
    subtitle: {
      textAlign: 'center',
      color: 'white',
      fontWeight: '300',
      fontSize: '14px'
    },
    heroButton: {
      border: '1px solid #FFFFFF',
      fontSize: '1rem',
      padding: '0.8rem 2rem',
      background: 'transparent',
      "&:hover": {
        backgroundColor: "#FFFFFF",
        color: "#1160F7"
      }
    },
    rightSectionStyle: {
      width: '100%',
      zIndex: '20',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    arrow: {
      marginTop: '8px',
      marginBottom: '-24px'
    }
  };
};
export default styles;